import { Toast } from "vant";
import qs from "qs";
import downloadConfig from '@/allConfig/index.js';
import router from '../../../router/index'
const browser = {
    versions: (function () {
        const u = navigator.userAgent,
            app = navigator.appVersion;
        return {
            trident: u.indexOf("Trident") > -1, //IE内核
            presto: u.indexOf("Presto") > -1, //opera内核
            webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Adr") > -1, //android终端
            iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, //是否iPad
            webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
            weixin: u.indexOf("MicroMessenger") > -1, //是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) == " qq", //是否QQ
        };
    })(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase(),
};
/**
 * @description:唤醒App或者跳转下载链接
 * @param {*} url 唤醒路径
 * @param {*} params 唤醒参数
 * @return {*}
 */
function download(baseUrl) {
    if (browser.versions.mobile) {
        // 微信需要浏览器打开再跳
        // 跳转页面，需要在app内打开指定页就这样，否则直接跳APP store
        // 跳转页面的js与这里是一样的，只是URL Scheme加上你要带的参数
        // var jumpLink = './jumpApp.html';
        if (browser.versions.weixin || browser.versions.qq) {
            Toast.fail({
                message: "点击右上角，选择在浏览器打开",
                duration: 5000,
            });
        } else {
            router.push({
                name: `download`
            })
            /* if (browser.versions.ios || browser.versions.iPad || browser.versions.iPhone) {
                router.push({
                    name: `download`
                })
                // 有APP直接跳，没有跳AppStore
                // let url;
                // if (qs.stringify(params)) {
                //     url = `goodsDetails://?${qs.stringify(params)}`;
                // } else {
                //     url = `goodsDetails`;
                // }
                // window.location.href = url;
                // console.log("这里是你的ios APP URL Scheme", url);
                // setTimeout(function () {
                //     // window.location.href = "这里是APP store的下载链接";
                //     console.log("这里是APP store的下载链接,ios");
                // }, 3000);
            } else if (browser.versions.android) {
                //安卓下载
                router.push({
                    name: `download`
                })

                // let url;
                // if (qs.stringify(params)) {
                //     url = `xdys://yhyg/${baseUrl}?${qs.stringify(params)}`;
                // } else {
                //     url = `xdys://yhyg/${baseUrl}`;
                // }
                // window.location.href = url;
            } */
        }
    } else {
        
    }
}

// 浏览器默认弹窗问题
/**
 * @description: 阻止浏览器默认行为
 * @param {*} event
 * @return {*}
 */
function closePreventDefault(event) {
    const e = event || window.event;
    if (e.preventDefault) {
        e.preventDefault();
    } else {
        e.returnValue = false;
    }
}

export { download, closePreventDefault };