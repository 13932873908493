<template>
    <div
        @touchmove.prevent
        class="page"
    >
        <scroller>
            <div class="content">
                <img
                    class="img"
                    src="@/assets/login_success.png"
                    alt=""
                >
                <div class="success">注册成功</div>
                <div class="dec">恭喜您，已成功注册平台账号点击</div>
                <div class="dec"> 下方按钮前往下载APP</div>
                <div
                    class="btn"
                    @click="downloadApp"
                >前往下载APP</div>
            </div>
        </scroller>
    </div>
</template>

<script>
import { download } from './js/download';
export default {
    components: {},
    data() {
        //这里存放数据
        return {
        };
    },
    //监听属性 类似于data概念
    computed: {},
    //方法集合
    methods: {
        downloadApp() {
            download();
        },
        getSystem() {
            let system = ''
            var u = navigator.userAgent
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
            var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                return "android"
            }
            if (isIOS) {
                return "ios"
            }
            return system;
        }

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 -   挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
}
</script>
<style lang='scss' scoped>
@import "./index.scss";
</style>
